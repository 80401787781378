<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar
        color="greyBase"
        dark
        :extended="!$vuetify.breakpoint.mobile"
        flat
        extension-height="55"
      >
        <v-toolbar-title>
          <b class="mr-3"><v-icon class="mr-2">anchor</v-icon>Ports</b>
          <v-btn
            id="info-guide"
            @click="infoStartGuide"
            fab
            height="4vh"
            width="4vh"
            style="position: absolute; z-index: 5; right: 1vw; top: 1vh"
          >
            <v-icon>help</v-icon>
          </v-btn>
        </v-toolbar-title>
      </v-toolbar>

      <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
        :loading="loadingPortInfo"
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-70px' : '0px',
          'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
        }"
      >
        <v-card-text
          :style="{
            height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
            'overflow-y': 'auto',
          }"
        >
          <v-row>
            <v-col cols="12" sm="3" md="2">
              <v-subheader style="font-size: 16px">
                Favourite Ports</v-subheader
              >
              <v-progress-linear
                v-if="loadingFavourites"
                indeterminate
                color="primary"
              ></v-progress-linear>
              <v-list
                v-if="!loadingFavourites"
                id="fav-port"
                class="scroll"
                max-height="28vh"
              >
                <v-list-item
                  v-for="fav in favourites"
                  :key="fav.id"
                  :style="{
                    border: '0.5px solid grey',
                    'border-left':
                      selectedPort && selectedPort.id == fav.unLocode.id
                        ? '3px solid var(--v-primary-base)'
                        : '0.5px solid grey',
                  }"
                  @click="selectedPort = fav.unLocode"
                >
                  <v-list-item-action>
                    <v-avatar size="32">
                      <v-img
                        v-if="fav.unLocode.countryCode"
                        contain
                        :src="`https://cdn.loglive.io/flags/4x3/${fav.unLocode.countryCode.toLowerCase()}.svg`"
                      >
                      </v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ fav.unLocode.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ fav.unLocode.locode }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="favourites.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No favourites</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-subheader style="font-size: 16px">
                Suggested Ports</v-subheader
              >
              <v-progress-linear
                v-if="loadingSuggestions"
                indeterminate
                color="primary"
              ></v-progress-linear>
              <v-list
                v-if="!loadingSuggestions"
                id="suggested-port"
                class="scroll"
                max-height="48vh"
              >
                <v-list-item
                  v-for="suggested in suggestedPorts"
                  :key="suggested.id"
                  :style="{
                    border: '0.5px solid grey',
                    'border-left':
                      selectedPort && selectedPort.id == suggested.id
                        ? '3px solid var(--v-primary-base)'
                        : '0.5px solid grey',
                  }"
                  @click="selectedPort = suggested"
                >
                  <v-list-item-action>
                    <v-avatar size="32">
                      <v-img
                        v-if="suggested.countryCode"
                        contain
                        :src="`https://cdn.loglive.io/flags/4x3/${suggested.countryCode.toLowerCase()}.svg`"
                      >
                      </v-img>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ suggested.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ suggested.locode }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="suggestedPorts.length == 0">
                  <v-list-item-content class="text-center">
                    <span style="color: grey">No suggestions</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="9" md="10">
              <div v-if="portInfoLoaded">
                <v-toolbar dense flat color="transparent">
                  <v-spacer></v-spacer>
                  <div v-if="selectedPort">
                    <v-btn
                      v-if="!favourited(selectedPort.id)"
                      rounded
                      small
                      outlined
                      @click="changeFavouriteStatus(selectedPort.id)"
                      :color="favourited(selectedPort.id) ? 'yellow' : 'grey'"
                      :loading="updatingFavourite"
                      style="text-transform: capitalize"
                    >
                      <v-icon left v-if="!favourited(selectedPort.id)"
                        >star_outline</v-icon
                      >
                      <v-icon v-else>grade</v-icon>
                      Add to Favourites
                    </v-btn>
                    <v-btn
                      v-else
                      color="grey"
                      style="text-transform: capitalize"
                      rounded
                      small
                      outlined
                      @click="changeFavouriteStatus(selectedPort.id)"
                      :loading="updatingFavourite"
                    >
                      <v-icon left>close</v-icon>
                      Remove From Favourites
                    </v-btn>
                  </div>
                  <v-autocomplete
                    auto-focus
                    hide-details
                    outlined
                    return-object
                    dense
                    hide-no-data
                    clearable
                    style="max-width: 300px"
                    prepend-inner-icon="search"
                    rounded
                    label="Search Port"
                    v-model="selectedPort"
                    :loading="loadingPorts"
                    :search-input.sync="portSearch"
                    :menu-props="{ closeOnContentClick: true }"
                    :items="ports"
                    :filter="filterObject"
                    item-text="name"
                    item-value="id"
                  >
                    <template v-slot:selection="data">
                      <v-avatar size="32" class="mr-2" v-if="data.item">
                        <v-img
                          v-if="data.item.countryCode"
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`"
                        >
                        </v-img>
                      </v-avatar>
                      <span v-if="data.item">
                        {{ data.item.name }}
                      </span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <v-img
                          contain
                          v-if="data.item.countryCode"
                          :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`"
                        >
                        </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.locode"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-toolbar>
                <v-progress-linear
                  v-if="loadingPortInfo"
                  indeterminate
                  color="primary"
                ></v-progress-linear>
                <v-card flat v-if="portInfoLoaded && selectedPort && selectedPort.id">
                  <v-card-text style="max-height: 80vh; overflow-y: auto">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-card color="modal" style="border-radius: 20px">
                          <v-card-title>
                            <v-avatar size="32" class="mr-2">
                              <v-img
                                v-if="selectedPort.countryCode"
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${selectedPort.countryCode.toLowerCase()}.svg`"
                              >
                              </v-img>
                            </v-avatar>
                            {{ selectedPort.name }} ({{ selectedPort.locode }})
                          </v-card-title>
                          <v-card-subtitle v-if="portInfo.info">
                            {{ portInfo.info.country.name }}
                          </v-card-subtitle>
                          <v-card-text>
                            <v-list
                              v-if="portInfo.voyages"
                              dense
                              style="max-height: 65vh; overflow-y: auto"
                            >
                              <v-list
                                dense
                                subheader
                                v-for="(week, index) in portInfo.voyages"
                                :key="index"
                              >
                                <v-divider></v-divider>
                                <v-subheader>Week: {{ week.week }}</v-subheader>
                                <v-divider></v-divider>
                                <v-list-item
                                  v-for="voyage in week.voyages"
                                  :key="voyage.id"
                                  style="border-bottom: 0.25px solid grey"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title v-if="voyage.vessel">
                                      <v-avatar
                                        size="24"
                                        class="mr-2"
                                        :color="
                                          voyage.vessel.countryCode
                                            ? 'transparent'
                                            : 'blue-grey'
                                        "
                                      >
                                        <v-img
                                          v-if="voyage.vessel.countryCode"
                                          contain
                                          :src="`https://cdn.loglive.io/flags/4x3/${voyage.vessel.countryCode.toLowerCase()}.svg`"
                                        >
                                        </v-img>
                                        <v-icon v-else color="white" small>
                                          directions_boat
                                        </v-icon>
                                      </v-avatar>
                                      {{ voyage.vessel.name }}
                                      <span
                                        v-if="voyage.importVoyage"
                                        style="color: grey"
                                      >
                                        - {{ voyage.importVoyage }}</span
                                      >
                                      <span
                                        style="color: grey"
                                        v-if="
                                          voyage.exportVoyage &&
                                          voyage.exportVoyage !=
                                            voyage.importVoyage
                                        "
                                      >
                                        - {{ voyage.exportVoyage }}</span
                                      >

                                      - Operator:
                                      <span style="color: grey">
                                        {{ voyage.operator }}
                                        <span v-if="voyage.service">
                                          - {{ voyage.service }}</span
                                        >
                                      </span>
                                    </v-list-item-title>

                                    <v-list-item-subtitle>
                                      <v-chip
                                        small
                                        outlined
                                        style="border: none"
                                      >
                                        <v-icon
                                          small
                                          left
                                          :color="
                                            voyage.isATA ? 'success' : 'orange'
                                          "
                                          >schedule</v-icon
                                        >
                                        ETA: {{ formatDate(voyage.eta) }}
                                      </v-chip>
                                      <v-chip
                                        small
                                        outlined
                                        style="border: none"
                                      >
                                        <v-icon
                                          small
                                          left
                                          :color="
                                            voyage.isATD ? 'success' : 'orange'
                                          "
                                          >schedule</v-icon
                                        >
                                        ETD: {{ formatDate(voyage.etd) }}
                                      </v-chip>
                                    </v-list-item-subtitle>

                                    <v-expand-transition :key="stackKey">
                                      <v-list
                                        dense
                                        v-if="openStacks.includes(voyage.id)"
                                      >
                                        <v-list-item
                                          v-for="stack in voyage.rmsVesselVoyageStacks"
                                          :key="stack.id"
                                        >
                                          <v-list-item-content>
                                            <v-chip
                                              small
                                              outlined
                                              style="border: none"
                                            >
                                              <v-icon small left>anchor</v-icon>
                                              {{ stack.terminal }}
                                            </v-chip>
                                            <!-- </subheader> -->
                                            <v-row align="center" class="mt-2">
                                              <v-col
                                                cols="12"
                                                sm="4"
                                                class="my-0 py-0"
                                              >
                                                <v-list-item
                                                  class="my-0 py-0"
                                                  v-if="stack.reeferStackStart"
                                                  style="
                                                    height: 15px;
                                                    font-size: 10px;
                                                  "
                                                >
                                                  <v-list-item-content>
                                                    <v-list-item-title>
                                                      <v-icon
                                                        x-small
                                                        color="blue"
                                                        class="mr-2"
                                                        >ac_unit</v-icon
                                                      >
                                                      Reefer Stacks
                                                    </v-list-item-title>
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item
                                                  class="my-0 py-0"
                                                  v-if="stack.dryStackFinish"
                                                  style="
                                                    height: 15px;
                                                    font-size: 10px;
                                                  "
                                                >
                                                  <v-list-item-content>
                                                    <v-list-item-title>
                                                      <v-icon
                                                        x-small
                                                        color="red"
                                                        class="mr-2"
                                                        >widgets</v-icon
                                                      >
                                                      Dry Stacks
                                                    </v-list-item-title>
                                                  </v-list-item-content>
                                                </v-list-item>
                                              </v-col>
                                              <v-col
                                                cols="12"
                                                sm="4"
                                                class="my-0 py-0"
                                              >
                                                <v-list-item
                                                  class="my-0 py-0"
                                                  v-if="stack.reeferStackStart"
                                                  style="
                                                    height: 15px;
                                                    font-size: 10px;
                                                  "
                                                >
                                                  <v-list-item-content>
                                                    <v-list-item-title>
                                                      <v-chip
                                                        small
                                                        outlined
                                                        style="border: none"
                                                      >
                                                        <v-icon
                                                          x-small
                                                          left
                                                          :color="
                                                            stack.reeferStackStartConfirmed
                                                              ? 'success'
                                                              : 'orange'
                                                          "
                                                          >fiber_manual_record</v-icon
                                                        >
                                                        {{
                                                          formatISODate(
                                                            stack.reeferStackStart
                                                          )
                                                        }}
                                                      </v-chip>
                                                    </v-list-item-title>
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item
                                                  class="my-0 py-0"
                                                  v-if="stack.dryStackStart"
                                                  style="
                                                    height: 15px;
                                                    font-size: 10px;
                                                  "
                                                >
                                                  <v-list-item-content>
                                                    <v-list-item-title>
                                                      <v-chip
                                                        small
                                                        outlined
                                                        style="border: none"
                                                      >
                                                        <v-icon
                                                          x-small
                                                          left
                                                          :color="
                                                            stack.dryStackStartConfirmed
                                                              ? 'success'
                                                              : 'orange'
                                                          "
                                                          >fiber_manual_record</v-icon
                                                        >
                                                        {{
                                                          formatISODate(
                                                            stack.dryStackStart
                                                          )
                                                        }}
                                                      </v-chip>
                                                    </v-list-item-title>
                                                  </v-list-item-content>
                                                </v-list-item>
                                              </v-col>
                                              <v-col
                                                cols="12"
                                                sm="4"
                                                class="my-0 py-0"
                                              >
                                                <v-list-item
                                                  class="my-0 py-0"
                                                  v-if="stack.reeferStackFinish"
                                                  style="
                                                    height: 15px;
                                                    font-size: 10px;
                                                  "
                                                >
                                                  <v-list-item-content>
                                                    <v-list-item-title>
                                                      <v-chip
                                                        small
                                                        outlined
                                                        style="border: none"
                                                      >
                                                        <v-icon
                                                          x-small
                                                          left
                                                          :color="
                                                            stack.reeferStackFinishConfirmed
                                                              ? 'success'
                                                              : 'orange'
                                                          "
                                                          >fiber_manual_record</v-icon
                                                        >
                                                        {{
                                                          formatISODate(
                                                            stack.reeferStackFinish
                                                          )
                                                        }}
                                                      </v-chip>
                                                    </v-list-item-title>
                                                  </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item
                                                  class="my-0 py-0"
                                                  v-if="stack.dryStackFinish"
                                                  style="
                                                    height: 15px;
                                                    font-size: 10px;
                                                  "
                                                >
                                                  <v-list-item-content>
                                                    <v-list-item-title>
                                                      <v-chip
                                                        small
                                                        outlined
                                                        style="border: none"
                                                      >
                                                        <v-icon
                                                          x-small
                                                          left
                                                          :color="
                                                            stack.dryStackFinishConfirmed
                                                              ? 'success'
                                                              : 'orange'
                                                          "
                                                          >fiber_manual_record</v-icon
                                                        >
                                                        {{
                                                          formatISODate(
                                                            stack.dryStackFinish
                                                          )
                                                        }}
                                                      </v-chip>
                                                    </v-list-item-title>
                                                  </v-list-item-content>
                                                </v-list-item>
                                              </v-col>
                                            </v-row>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <!-- <subheader> -->
                                      </v-list>
                                    </v-expand-transition>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-btn
                                      icon
                                      v-if="
                                        voyage.rmsVesselVoyageStacks &&
                                        voyage.rmsVesselVoyageStacks.length > 0
                                      "
                                      @click="viewStacks(voyage)"
                                    >
                                      <v-icon
                                        v-if="
                                          voyage.rmsVesselVoyageStacks &&
                                          !openStacks.includes(voyage.id)
                                        "
                                        >expand_more</v-icon
                                      >
                                      <v-icon v-else>expand_less</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-list>
                          </v-card-text>
                      
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-card
                          flat
                          :key="weatherKey"
                          v-if="selectedPort.lat && selectedPort.lng"
                        >
                          <v-card-text>
                            <iframe
                              width="100%"
                              style="border-radius: 20px"
                              height="450"
                              :src="`https://embed.windy.com/embed2.html?lat=${selectedPort.lat}&lon=${selectedPort.lng}&detailLat=${selectedPort.lat}&detailLon=${selectedPort.lng}&width=650&height=450&zoom=11&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1`"
                              frameborder="0"
                            ></iframe>
                          </v-card-text>
                        </v-card>
                        <v-container
                          v-if="
                            portInfo.congestion &&
                            (portInfo.congestion.congestion ||
                              portInfo.congestion.gapWithMean)
                          "
                        >
                          <v-row justify="end">
                            <v-col cols="12" sm="9">
                              <v-card flat>
                                <v-card-text>
                                  <v-list>
                                    <v-list-item>
                                      <v-list-item-action>
                                        <v-icon
                                          color="success"
                                          v-if="
                                            portInfo.congestion.gapWithMean < 0
                                          "
                                          >check_circle_outline</v-icon
                                        >
                                        <v-icon
                                          color="orange"
                                          v-else-if="
                                            portInfo.congestion.gapWithMean > 0
                                          "
                                          >warning</v-icon
                                        >
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Estimated Port Congestion
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          <v-chip
                                            v-if="
                                              portInfo.congestion.gapWithMean >
                                              0
                                            "
                                            small
                                            outlined
                                            style="border: none"
                                            color="red"
                                          >
                                            <v-icon left>
                                              arrow_drop_up
                                            </v-icon>
                                            +
                                            {{
                                              portInfo.congestion.gapWithMean
                                            }}% above average
                                          </v-chip>
                                          <v-chip
                                            v-else-if="
                                              portInfo.congestion.gapWithMean <
                                              0
                                            "
                                            small
                                            outlined
                                            style="border: none"
                                            color="success"
                                          >
                                            <v-icon left>
                                              arrow_drop_down
                                            </v-icon>
                                            -
                                            {{
                                              portInfo.congestion.gapWithMean
                                            }}% below average
                                          </v-chip>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle
                                          style="font-size: 10px"
                                        >
                                          Last Updated:
                                          {{
                                            formatDateTime(
                                              portInfo.congestion.time
                                            )
                                          }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        {{ portInfo.congestion.congestion }}%
                                      </v-list-item-action>
                                    </v-list-item>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
              <div
                v-else
                style="
                  height: 30vh;
                  padding-top: 0;
                  margin-top: 0;
                  background: transparent;
                "
                class="text-center"
              >
                <valhalla-loading />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn
          @click="infoStartGuide"
          icon
          style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0"
          ><v-icon color="primary" size="30">close</v-icon></v-btn
        >
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Company Profiles!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>
              FRIKO <br />• Map out routes for transporters <br />• Add GeoJSON
              data to locations used determine if a vehicle is within a certain
              area <br />• Calculate accurate waiting times at loading points
              <br />• Calculate distances for billing purposes <br />• Pin-point
              locations for the purpose of reporting and analysis <br />• Map
              out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the
            button below to start the tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import axios from 'axios'
import dateFormat from "dateformat";
import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
// import steps from "./Steps/Port";
export default {
  data: () => ({
    // guides
    steps: null,
    
    driver: null,
    infoStart: false,
    displayArrivalsDepartures: true,
    displayExpectedArrivals: true,
    displayWeatherInfo: true,
    displayStackDates: true,
    displayTerminalInfo: true,
    displayVesselsInPort: true,
    expectedArrivalHeaders: [
      { text: "Vessel", value: "shipName" },
      { text: "Scheduled Arrival", value: "reportedETA" },
    ],
    favourites: [],
    loadingFavourites: false,
    loadingPorts: false,
    loadingPortInfo: false,
    loadingSuggestions: false,
    openStacks: [],
    options: {
      list: {
        // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
        windowed: 600,

        // Because: #app {padding: 80px 24px;}
        // padding: 24,
      },
      position: {
        start: 0,
      },
      autoplay: { play: false, repeat: false, speed: 2500 },
    },
    portInfoLoaded: true,
    portSearch: null,
    ports: [],
    portInfo: {},
    recentHeaders: [
      { text: "Vessel", value: "shipName" },
      { text: "Arrival", value: "arrival" },
      { text: "Departure", value: "departure" },
    ],
    selectedPort: null,
    searchTimer: null,
    stackDateHeaders: [
      { text: "Vessel", value: "vesselName" },
      { text: "Status", value: "stackStatus" },
      { text: "Stack Open", value: "stackOpen" },
      { text: "Stack Close", value: "stackClose" },
    ],
    stackKey: 100,
    suggestedPorts: [],
    terminalHeaders: [
      { text: "Terminal", value: "name" },
      { text: "No. Berths", value: "numberOfBerths" },
      { text: "No. Vessels", value: "numberOfVessels" },
    ],
    vesselsInPortHeaders: [
      { text: "Flag", value: "flag", sortable: false },
      { text: "Vessel", value: "shipName" },
    ],
    updatingFavourite: false,
    weatherKey: 1000,
  }),
  async mounted() {
    this.driver = new Driver({
      animate: false,
    });
  },
  watch: {
    async portSearch(val) {
      if (val) {
        await this.getMonitoredPorts();
      }
    },
    "selectedPort.locode": {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadPortInfo();
          // weather.setCity(this.selectedPort.name);
        }
      },
    },
  },
  async created() {
    this.getFavouritePorts();
    this.getSuggestedPorts();
    await this.getSteps();
    // weather.setLang('en');
  },
  methods: {
    async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'views/Port/Steps',
        });
        this.steps = steps.steps
    },
    async changeFavouriteStatus(id) {
      this.updatingFavourite = true;
      if (!this.favourited(id)) {
        await this.$API.createFavouritePort({
          unLocodeId: id,
        });
        // this.favourites.push(result)
        this.getFavouritePorts();
      } else {
        let find = this.favourites.find((x) => x.unLocodeId === id);
        await this.$API.updateFavouritePort({
          id: find.id,
          isActive: false,
          isDeleted: true,
        });
        let index = this.favourites.indexOf(find);
        this.favourites.splice(index, 1);
      }
      this.updatingFavourite = false;
    },
    compassDirection(angle) {
      let val = angle / 22.5 + 0.5;
      let arr = [
        "N",
        "NNE",
        "NE",
        "ENE",
        "E",
        "ESE",
        "SE",
        "SSE",
        "S",
        "SSW",
        "SW",
        "WSW",
        "W",
        "WNW",
        "NW",
        "NNW",
      ];
      return arr[Math.round(val % 16) - 1];
    },
    favourited(id) {
      return this.favourites.map((x) => x.unLocodeId).includes(id);
    },
    filterObject(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.locode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "ddd, dd mmm HH:MM");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
      }
      return result;
    },
    formatISODate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getFavouritePorts() {
      this.loadingFavourites = true;
      this.favourites = await this.$API.getFavouritePorts();
      if (
        this.favourites.length > 0 &&
        (!this.selectedPort || !this.selectedPort.id)
      ) {
        this.selectedPort = this.favourites[0].unLocode;
      }
      this.loadingFavourites = false;
    },
    async getSuggestedPorts() {
      this.loadingSuggestions = true;
      this.suggestedPorts = await this.$API.getSuggestedPorts();
      this.loadingSuggestions = false;
    },
    async getMonitoredPorts() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.loadingPorts = true;
      this.searchTimer = setTimeout(async () => {
        this.ports = await this.$API.getPorts({
          params: {
            search: this.portSearch,
          },
        });
        this.loadingPorts = false;
      }, 500);
    },
    async loadPortInfo() {
      this.loadingPortInfo = true;
      this.portInfoLoaded = false;
      // let result = await axios.get('https://embed.windy.com/embed2.html?lat=-33.92&lon=18.42&detailLat=-33.92&detailLon=18.42&width=650&height=450&zoom=11&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1')
      // console.log(result)
      this.portInfo = await this.$API.getPortInfo(this.selectedPort.id);

      // await weather.setUnits('metric');
      // await weather.setAPPID("4b5f2e9106f59226c452e98791e81b11");
      // await weather.getAllWeather((err, JSONObj) => {
      //     this.portInfo.weather = JSONObj
      //     this.weatherKey++
      // });
      this.portInfoLoaded = true;
      this.loadingPortInfo = false;
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();
      this.driver.defineSteps(this.steps);
      this.driver.start();
    },
    viewStacks(voyage) {
      let index = this.openStacks.indexOf(voyage.id);
      if (index == -1) {
        this.openStacks.push(voyage.id);
      } else {
        this.openStacks.splice(index, 1);
      }
      this.stackKey++;
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow-y: auto;
}
</style>